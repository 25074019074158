// REACT
import React from "react"
// MUI
import { List, ListItem, ListItemText, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

// STYLES
const useStyles = makeStyles({
  heading: {
    fontSize: "1.8rem",
    fontWeight: 600,
  },
  featureList: {
    marginTop: "1rem",
  },
  listItem: {
    padding: "0rem",
    marginBottom: "0.25rem",
  },
})

// COMPONENT FUNCTION
export const FeaturesList = ({ features, title = "Features" }) => {
  // STYLE OBJECT
  const classes = useStyles()

  // COMPONENT FUNCTION
  return (
    <>
      <Typography variant="h2" className={classes.heading}>
        {title}
      </Typography>
      <List className={classes.featureList}>
        {features.map(feature => (
          <ListItem key={feature} className={classes.listItem}>
            <ListItemText primary={feature} />
          </ListItem>
        ))}
      </List>
    </>
  )
}
