// REACT
import React from "react"
// MUI
import { Link, Typography } from "@material-ui/core"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageBlock } from "../components/imageBlock"
import { ImageWithCaption } from "../components/imageWithCaption"
import { FeaturesList } from "../components/featuresList"

// COMPONENT FUNCTION
const ServicePage = () => {
  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      baySailsMarineServiceDepartment: file(
        relativePath: { eq: "bay-sails-marine-service-department.jpg" }
      ) {
        ...BsmFluidImage
      }
      steppingSailboatMast: file(
        relativePath: { eq: "stepping-sailboat-mast.jpg" }
      ) {
        ...BsmFluidImage
      }
      fiberglassFabrication: file(
        relativePath: { eq: "fiberglass-fabrication.jpg" }
      ) {
        ...BsmFluidImage
      }
      outboardMotorRepair: file(
        relativePath: { eq: "outboard-motor-repair.jpg" }
      ) {
        ...BsmFluidImage
      }
    }
  `)

  // FEATURES
  const features = [
    "Hauling",
    "Engine Repair / Maintenance",
    "Winterizing / Summerizing",
    "Bottom Painting",
    "Powerwashing",
    "Fiberglass Fabrication",
    "Fiberglass Repair",
    "Moorings",
    "Shrinkwrapping",
    "Sailboat Rigging",
    "Boat Rentals           ",
    "… and more",
  ]

  // COMPONENT
  return (
    <Layout title="Marine Service">
      <PageTitle title="MARINE SERVICE" />

      <ImageBlock>
        <ImageWithCaption
          fluid={data.baySailsMarineServiceDepartment.childImageSharp.fluid}
          caption="Bay Sails Marine crew"
          displayCaption
          maxWidth={480}
        />
      </ImageBlock>

      <Typography paragraph>
        We are an authorized dealer and certified repair shop for Evinrude and
        Johnson outboard motors. You can rely on us to provide the service your
        boat, trailer, or motor needs.
      </Typography>

      <ImageBlock>
        <ImageWithCaption
          fluid={data.steppingSailboatMast.childImageSharp.fluid}
          caption="Bay Sails unstepping a boat mast"
          displayCaption
          maxWidth={480}
        />
      </ImageBlock>

      <FeaturesList
        features={features}
        title="Some of the services we provide:"
      />

      <ImageBlock>
        <ImageWithCaption
          fluid={data.fiberglassFabrication.childImageSharp.fluid}
          caption="Fiberglass center console"
          displayCaption
          maxWidth={480}
        />
      </ImageBlock>

      <Typography variant="h6" component="h2" gutterBottom>
        Eletronic Parts Catalog
      </Typography>
      <Typography paragraph>
        <Link href="http://epc.brp.com/default.aspx?brands=ej&lang=E&dealerlocator=no">
          Click here
        </Link>{" "}
        to use the <strong>Bombardier Electronic Parts Catalog</strong>. This
        will let you see diagrams of your Evinrude or Johnson motor. You’ll be
        able to find the exact part number you need and print a pick ticket that
        you can bring to us.
      </Typography>

      <ImageBlock>
        <ImageWithCaption
          fluid={data.outboardMotorRepair.childImageSharp.fluid}
          caption="Repairing an engine"
          displayCaption
          maxWidth={480}
        />
      </ImageBlock>
    </Layout>
  )
}

export default ServicePage
